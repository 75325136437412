export const EXHIBITOR_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment exhibitorActionButtonAddnoteFragment on Exhibitor {
    __typename
    id
    uid
    name
    logoFileResource {
      schemaCode
      path
    }
  }
`;
